import React, { useState } from "react"
import axios from "axios"
import classnames from "classnames"
import * as qs from "query-string"

/* Import Global Components */
import FadeIn from "~components/animations/fadeInChildElements"
import Form from "~components/form/form"
import Input from "~components/form/input"
import PhoneNumber from "~components/form/phoneNumber"
import Checkbox from "~components/form/checkbox"
import TextArea from "~components/form/textArea"
import Submit from "~components/form/submit"
import ThankYou from "~components/form/thankYou"
import SmallDownArrowSvg from "~components/icon/normal/smallDownArrow"
import SmallUpArrowSvg from "~components/icon/normal/smallUpArrow"

/* Import Local Styles */
import "./contact-form.css"

const ContactForm = ({ startAnimation }) => {
  const [showContactForm, setShowContactForm] = useState(false)
  const [canSubmit, setCanSubmit] = useState(false)
  const [submitted, setSubmitted] = useState(false)

  const disableButton = () => {
    setCanSubmit(false)
  }

  const enableButton = () => {
    setCanSubmit(true)
  }

  const handleSubmit = async (data, reset) => {
    const {
      email,
      emailUpdates,
      name,
      organization,
      phone,
      questionsAndDetails,
    } = data

    const formData = {
      "form-name": "contact",
      email: email,
      emailUpdates: emailUpdates,
      name: name,
      organization: organization,
    }

    if (phone) {
      formData.phone = phone
    }
    if (questionsAndDetails) {
      formData.questionsAndDetails = questionsAndDetails
    }

    const result = await fetch("/contact", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: qs.stringify(formData),
    })
    if (result.status === 200) {
      setSubmitted(true)
      reset()
    }

    axios.post("/api/subscribe", {
      email,
      emailUpdates,
      name,
      phone,
    })
  }

  const handleToggleContactForm = () => {
    setShowContactForm(!showContactForm)
  }

  return (
    <div
      className={classnames("contact-form", {
        "show-contact-form": showContactForm,
      })}
    >
      <button className="contact-form-toggle" onClick={handleToggleContactForm}>
        {showContactForm ? (
          <span>
            <SmallDownArrowSvg />
          </span>
        ) : (
          <span>
            <SmallUpArrowSvg />
          </span>
        )}
        <span>Contact Form</span>
      </button>
      <Form
        handleValidSubmit={handleSubmit}
        handleOnValid={enableButton}
        handleOnInvalid={disableButton}
        dataNetlify="true"
        name="contact"
      >
        <input type="hidden" name="contact" value="contact" />
        <FadeIn delay={325} transitionDuration={520} start={startAnimation}>
          <Input
            placeholder="Name"
            name="name"
            validations="isExisty"
            required
          />
          <Input
            type="email"
            placeholder="Email"
            name="email"
            validations="isEmail"
            required
          />
          <Input
            type="organization"
            placeholder="Organization"
            name="organization"
            validations="isExisty"
            required
          />
          <Checkbox
            value={true}
            name="emailUpdates"
            label="Send me updates 1x a month or so"
          />
          <PhoneNumber type="tel" placeholder="Phone" name="phone" />
          <TextArea
            placeholder="Questions / Details"
            name="questionsAndDetails"
          />
          {!submitted ? (
            <Submit disabled={!canSubmit}>
              <span>Submit</span>
            </Submit>
          ) : (
            <ThankYou>
              <span>Thank You</span>
            </ThankYou>
          )}
        </FadeIn>
      </Form>
    </div>
  )
}

export default ContactForm
