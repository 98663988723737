import React, { useState } from "react"
import { graphql } from "gatsby"

/* Import Global Components */
import Page from "~components/page"

/* Import Local Components */
import ContactInformation from "./components/contactInformation/_contactInformation"
import ContactForm from "./components/contactForm/_contactForm"

/* Import Local Styles */
import "./contact.css"

const Contact = ({ data: { sanityContactSettings: contactInformation } }) => {
  const [
    contactInformationAnimationFinished,
    setContactInformationAnimationFinished,
  ] = useState(false)

  return (
    <Page className="contact">
      <div className="container">
        <ContactInformation
          contactInformation={contactInformation}
          setContactInformationAnimationFinished={
            setContactInformationAnimationFinished
          }
        />
        <ContactForm startAnimation={contactInformationAnimationFinished} />
      </div>
    </Page>
  )
}

export default Contact

export const query = graphql`
  query ContactPageQuery {
    sanityContactSettings(_id: { eq: "contactSettings" }) {
      id
      _id
      generalQuestionsEmail
      generalQuestionsPhone
      newBusinessEmail
      newBusinessPhone
      socialLinks {
        title
        url
        _key
      }
    }
  }
`
