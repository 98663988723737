import React from "react"

/* Import Local Styles */
import "../icon.css"

const DownArrowSvg = () => (
  <svg
    viewBox="0 0 80 44"
    className="icon no-fill"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M39.6753 39.6609C44.6643 33.581 48.2616 28.5555 50.5198 24.607C52.778 20.6584 60.4715 6.43449 60.9966 4.8416C63.4123 -2.67417 73.0752 0.803275 73.0752 0.803275C73.0752 0.803275 79.167 5.31274 79.167 8.04982C77.7491 8.85749 76.3312 9.77733 74.887 10.7869C73.4691 11.7965 71.9724 12.7612 70.4494 13.681C64.4101 17.9437 58.6597 22.6775 53.1981 27.8825C47.7365 33.1099 43.2464 38.2475 39.6753 43.3179L39.6753 39.6609ZM39.6753 43.2954C35.9993 38.2251 31.4304 33.0874 25.9688 27.86C20.5072 22.6327 14.7567 17.8988 8.71745 13.6586C7.29953 12.7388 5.82909 11.7741 4.35866 10.7645C2.86197 9.75489 -0.000122421 8.02738 -0.000122421 8.02738C-0.000122421 8.02738 6.328 1.11736 9.45267 1.11736C12.5773 1.11736 16.9886 2.05964 18.5116 3.89932C21.5575 9.48567 26.3627 20.6584 28.6208 24.6294C30.879 28.578 34.5551 33.6034 39.6491 39.6834L39.6491 43.2954L39.6753 43.2954Z"
      className="fill-with-text-color"
    />
  </svg>
)

export default DownArrowSvg
