import React from "react"

/* Import Local Styles */
import "../icon.css"

const UpArrowSvg = () => (
  <svg
    viewBox="0 0 80 44"
    className="icon no-fill"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M39.4917 3.65694C34.5027 9.73686 30.9054 14.7623 28.6472 18.7109C26.389 22.6595 18.6955 36.8834 18.1704 38.4763C15.7546 45.992 6.0918 42.5146 6.0918 42.5146C6.0918 42.5146 6.33538e-08 38.0051 9.59933e-08 35.268C1.41792 34.4604 2.83584 33.5405 4.28001 32.531C5.69793 31.5214 7.19462 30.5567 8.71757 29.6368C14.7569 25.3742 20.5073 20.6403 25.9689 15.4354C31.4305 10.208 35.9206 5.07035 39.4917 1.30477e-05L39.4917 3.65694ZM39.4917 0.0224473C43.1677 5.09279 47.7366 10.2304 53.1982 15.4578C58.6598 20.6852 64.4103 25.419 70.4495 29.6593C71.8675 30.5791 73.3379 31.5438 74.8083 32.5534C76.305 33.563 79.1671 35.2905 79.1671 35.2905C79.1671 35.2905 72.839 42.2005 69.7143 42.2005C66.5897 42.2005 62.1784 41.2582 60.6554 39.4185C57.6095 33.8322 52.8043 22.6595 50.5462 18.6885C48.288 14.7399 44.6119 9.71443 39.5179 3.63451L39.5179 0.0224473L39.4917 0.0224473Z"
      className="fill-with-text-color"
    />
  </svg>
)

export default UpArrowSvg
