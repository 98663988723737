import React from "react"
import { Link } from "gatsby"

/* Import Global Components */
import FadeIn from "~components/animations/fadeInChildElements"
import PuppyMouthSvg from "~components/icon/normal/puppyMouth"

/* Import Local Styles */
import "./contact-information.css"

const ContactInformation = ({
  contactInformation,
  setContactInformationAnimationFinished,
}) => (
  <div className="contact-information">
    <FadeIn
      delay={325}
      transitionDuration={520}
      callback={() => {
        setContactInformationAnimationFinished(true)
      }}
    >
      <div className="puppy-love">
        <p className="title">Puppylove Hq</p>
        <p>
          We’re in New York City & Los Angeles but work with clients and talent
          worldwide.
        </p>
      </div>
      <div className="emails">
        <div className="new-business">
          <p className="title">New Business</p>
          <a
            href={`mailto:${contactInformation.newBusinessEmail}`}
            target="_blank"
            rel="noreferrer"
          >
            {contactInformation.newBusinessEmail}
          </a>
          <a
            href={`tel:${contactInformation.newBusinessPhone}`}
            target="_blank"
            rel="noreferrer"
          >
            {contactInformation.newBusinessPhone}
          </a>
        </div>
        <div className="general-questions">
          <p className="title">Questions and Love</p>
          <a
            href={`mailto:${contactInformation.generalQuestionsEmail}`}
            target="_blank"
            rel="noreferrer"
          >
            {contactInformation.generalQuestionsEmail}
          </a>
          <a
            href={`tel:${contactInformation.generalQuestionsPhone}`}
            target="_blank"
            rel="noreferrer"
          >
            {contactInformation.generalQuestionsPhone}
          </a>
        </div>
      </div>
      <div className="social-links">
        <ul>
          {contactInformation.socialLinks.map(link => (
            <li key={link._key}>
              <a href={link.url} target="_blank" rel="noreferrer">
                {link.title}
              </a>
            </li>
          ))}
        </ul>
      </div>
      <Link to="/signup" className="get-updates">
        <span>Sign Up</span>
        <span className="peace-icon">
          <PuppyMouthSvg />
        </span>
      </Link>
    </FadeIn>
  </div>
)

export default ContactInformation
