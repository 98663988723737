import React from "react"
import { SwitchTransition, CSSTransition } from "react-transition-group"
import { withFormsy } from "formsy-react"

/* Import Global Assets */
import HeartIcon from "~components/icon/normal/heart"
import HeartFilledIcon from "~components/icon/normal/heartFilled"

/* Import Local Styles */
import "./text-area.css"

// onChange is required
const Input = ({
  type = "text",
  placeholder = "",
  value = "",
  setValue,
  errorMessage,
  style = {},
  isValid = false,
  name,
}) => {
  const handleOnChange = event => {
    // setValue() will set the value of the component, which in
    // turn will validate it and the rest of the form
    // Important: Don't skip this step. This pattern is required
    // for Formsy to work.
    setValue(event.currentTarget.value)
  }

  return (
    <div className="input textarea" style={style}>
      <textarea
        onChange={handleOnChange}
        type={type}
        value={value}
        placeholder={placeholder}
        name={name}
      />
      <span className="icon">
        <SwitchTransition>
          <CSSTransition
            key={value && value.length > 0}
            addEndListener={(node, done) =>
              node.addEventListener("transitionend", done, false)
            }
            classNames="fade"
          >
            {value && value.length > 0 ? <HeartFilledIcon /> : <HeartIcon />}
          </CSSTransition>
        </SwitchTransition>
      </span>
      <span className="error">{errorMessage}</span>
    </div>
  )
}

export default withFormsy(Input)
